import { createBrowserHistory } from 'history';
import React, { useContext } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { LoginContext } from '../../Contexts/loginContext';
import { localize } from '../../Localization/localize';
import { UserType } from '../../Swagger/api';
import { AdminUserAdmins } from '../admin/adminUserAdmins';
import { AdminUserAuditors } from '../admin/adminUserAuditors';
import { AdminUserCompanies } from '../admin/adminUserCompanies';
import { AdminUserSuperAuditors } from '../admin/adminUserSuperAuditors';
import { GraphicAssigment } from '../admin/graphicAssigment';
import { AdminOverview } from '../admin/overview/adminOverview';
import { ControlDataReadOnly } from '../admin/overview/controlDataReadOnly';
import { AdminQuestions } from '../admin/questions/adminQuestions';
import { QuestionAddEdit } from '../admin/questions/questionAddEdit';
import { ContentVersions } from '../admin/questions/starVersions/contentVersions';
import { EditStarVersion } from '../admin/questions/starVersions/editStarVersion';
import { Archive } from '../archive/archive';
import { AuditorHome } from '../auditor/auditorHome';
import { AuditorOverview } from '../auditor/auditorOverview';
import { AuditorProfile } from '../auditor/auditorProfile';
import { CompanyProfileReadOnly } from '../auditor/companyProfileReadOnly';
import { QuestionsIndex } from '../auditor/questionsIndex';
import { CompanyHome } from '../company/companyHome';
import { CompanyOverview } from '../company/overview/companyOverview';
import { CompanyProfile } from '../company/profile/companyProfile';
import { GraphicalDisplayCompany } from '../graphicalDisplay/graphicalDisplayCompany';
import { NavigationButton } from '../navigationButton/NavigationButton';
import { PasswordForgot } from '../passwordForgot/passwordForgot';
import { PasswordSet } from '../passwordSet/passwordSet';
import { SignIn } from '../signin/signIn';
import { StarAdmin } from '../star/starAdmin/starAdmin';
import { ControlData } from '../star/starAudit/controlData';
import { StarAudit } from '../star/starAudit/starAudit';
import { StartAudit } from '../star/starAudit/startAudit';
import { StarRun } from '../star/starRun/starRun';
import { StarStart } from '../star/starStart';
import { UserMenu } from '../userMenu/UserMenu';
import { Offline } from './offline';
import { PageNotFound } from './pageNotFound';

export const history = createBrowserHistory();

export const paths = {
  home: '/',
  archive: '/archive',
  signin: {
    root: '/signin/',
    forgot: '/signin/forgot',
    reset: '/signin/reset',
  },
  admin: {
    root: '/admin/',
    overview: '/admin/overview',

    users: {
      root: '/admin/users',
      admins: '/admin/users/admins',
      companies: '/admin/users/companies',
      auditors: '/admin/users/auditors',
      superAuditors: '/admin/users/superauditors',
    },
    questions: '/admin/questions',
    starAdmin: '/admin/star',
    controlData: '/admin/star/control/:id',
    graphicAssignment: '/admin/graphic',
    contentVersions: '/admin/versions',
    editContentVersions: '/admin/versions/:id',
    questiondId: '/admin/question/:id?',
  },
  company: {
    root: '/company/',
    overview: '/company/overview',
    profile: '/company/profile',
    graphicalDisplay: '/company/graphicaldisplay',
  },
  auditor: {
    root: '/auditor/',
    overview: '/auditor/overview',
    profile: '/auditor/profile',
    companyProfile: '/auditor/companyProfile/:companyId',
    questionIndex: '/auditor/questionIndex/:companyId/:starId',
  },
  star: {
    starRun: '/star/run/:starId',
    startStar: '/star/start',
    auditStar: '/star/audit',
    startAudit: '/star/audit/start',
    controlData: '/star/audit/control/:starId',
  },
};

const isStage = window.location.hostname.toLocaleLowerCase().startsWith('stage');

export const Layout = () => {
  const loginContext = useContext(LoginContext);

  let components: JSX.Element[] = [];
  if (!loginContext.login) {
    components = [
      <Route exact path={paths.signin.root} key={paths.signin.root} component={SignIn} />,
      <Route exact path={paths.signin.forgot} key={paths.signin.forgot} component={PasswordForgot} />,
      <Route exact path={paths.signin.reset} key={paths.signin.reset} component={PasswordSet} />,
    ];
  }
  if (loginContext.login) {
    // Admin
    if (loginContext.login.userType === 0) {
      components = [
        <Route exact path={paths.admin.overview} key={paths.admin.overview} component={AdminOverview} />,
        <Route exact key={paths.admin.users.admins} path={paths.admin.users.admins} component={AdminUserAdmins} />,
        <Route exact key={paths.admin.users.companies} path={paths.admin.users.companies} component={AdminUserCompanies} />,
        <Route exact key={paths.admin.users.auditors} path={paths.admin.users.auditors} component={AdminUserAuditors} />,
        <Route
          exact
          key={paths.admin.users.superAuditors}
          path={paths.admin.users.superAuditors}
          component={AdminUserSuperAuditors}
        />,
        <Route exact path={paths.admin.questions} key={paths.admin.questions} component={AdminQuestions} />,
        <Route exact path={paths.admin.starAdmin} key={paths.admin.starAdmin} component={StarAdmin} />,
        <Route exact path={paths.admin.controlData} key={paths.admin.controlData} component={ControlDataReadOnly} />,
        <Route exact path={paths.admin.graphicAssignment} key={paths.admin.graphicAssignment} component={GraphicAssigment} />,
        <Route exact path={paths.admin.contentVersions} key={paths.admin.contentVersions} component={ContentVersions} />,
        <Route exact path={paths.signin.reset} key={paths.signin.reset} component={PasswordSet} />,
        <Route exact path={paths.archive} key={paths.archive} component={Archive} />,
        <Route
          exact
          path={paths.admin.editContentVersions}
          key={paths.admin.editContentVersions}
          component={EditStarVersion}
        />,
        <Route exact path={paths.admin.questiondId} key={paths.admin.questiondId} component={QuestionAddEdit} />,
      ];
    }

    // Company
    else if (loginContext.login.userType === 1) {
      components = [
        <Route exact path={paths.company.root} key={paths.company.root} component={CompanyHome} />,
        <Route exact path={paths.company.overview} key={paths.company.overview} component={CompanyOverview} />,
        <Route exact path={paths.company.profile} key={paths.company.profile} component={CompanyProfile} />,
        <Route
          exact
          path={paths.company.graphicalDisplay}
          key={paths.company.graphicalDisplay}
          component={GraphicalDisplayCompany}
        />,
        <Route path={paths.star.starRun} key={paths.star.starRun} component={StarRun} />,
        <Route path={paths.star.startStar} key={paths.star.startStar} component={StarStart} />,
        <Route exact path={paths.signin.reset} key={paths.signin.reset} component={PasswordSet} />,
      ];
    }

    //Auditor
    else if (loginContext.login.userType === 2) {
      components = [
        <Route exact path={paths.auditor.root} component={AuditorHome} key={paths.auditor.root} />,
        <Route exact path={paths.auditor.overview} component={AuditorOverview} key={paths.auditor.overview} />,
        <Route exact path={paths.auditor.profile} component={AuditorProfile} key={paths.auditor.profile} />,
        <Route
          exact
          path={paths.auditor.companyProfile}
          component={CompanyProfileReadOnly}
          key={paths.auditor.companyProfile}
        />,
        <Route exact path={paths.auditor.questionIndex} component={QuestionsIndex} key={paths.auditor.questionIndex} />,
        <Route exact path={paths.star.auditStar} component={StarAudit} key={paths.star.auditStar} />,
        <Route exact path={paths.star.startAudit} component={StartAudit} key={paths.star.startAudit} />,
        <Route exact path={paths.star.controlData} component={ControlData} key={paths.star.controlData} />,
        <Route exact path={paths.signin.reset} key={paths.signin.reset} component={PasswordSet} />,
        <Route exact path={paths.archive} key={paths.archive} component={Archive} />,
      ];
    }

    // Super Auditor
    else if (loginContext.login.userType === 3) {
      components = [
        <Route exact path={paths.admin.overview} key={paths.admin.overview} component={AdminOverview} />,
        <Route exact key={paths.admin.users.auditors} path={paths.admin.users.auditors} component={AdminUserAuditors} />,
        <Route exact path={paths.admin.starAdmin} key={paths.admin.starAdmin} component={StarAdmin} />,
        <Route exact path={paths.admin.controlData} key={paths.admin.controlData} component={ControlDataReadOnly} />,
        <Route exact path={paths.signin.reset} key={paths.signin.reset} component={PasswordSet} />,
        <Route exact path={paths.archive} key={paths.archive} component={Archive} />,
      ];
    }
  }

  const getNavigationButtons = (props: any, userType?: UserType) => {
    switch (userType) {
      case UserType.Admin:
        return [
          <NavigationButton to={paths.admin.overview} label={localize.starsOverview} key={paths.admin.overview} {...props} />,
          <NavigationButton
            to={paths.admin.users.admins}
            label={localize.userManagement}
            key={paths.admin.users.admins}
            alternateMatches={[paths.admin.users.admins, paths.admin.users.companies, paths.admin.users.auditors]}
            {...props}
          />,
          <NavigationButton to={paths.admin.questions} label={localize.questions} key={paths.admin.questions} {...props} />,
          <NavigationButton
            to={paths.admin.graphicAssignment}
            label={localize.graphicalResult}
            key={paths.admin.graphicAssignment}
            {...props}
          />,
          <NavigationButton to={paths.archive} label={localize.archive} key={paths.archive} {...props} />,
        ];

      case UserType.Company:
        return [
          <NavigationButton to={paths.company.root} label={localize.homeInfo} key={paths.company.root} {...props} />,
          <NavigationButton to={paths.company.profile} label={localize.myProfile} key={paths.company.profile} {...props} />,
          <NavigationButton to={paths.company.overview} label={localize.myStars} key={paths.company.overview} {...props} />,
          <NavigationButton
            to={paths.company.graphicalDisplay}
            label={localize.graphicalResult}
            key={paths.company.graphicalDisplay}
            {...props}
          />,
        ];

      case UserType.Auditor:
        return [
          <NavigationButton to={paths.auditor.root} label={localize.homeInfo} key={paths.auditor.root} {...props} />,
          <NavigationButton to={paths.auditor.profile} label={localize.myProfile} key={paths.auditor.profile} {...props} />,
          <NavigationButton to={paths.auditor.overview} label={localize.companies} key={paths.auditor.overview} {...props} />,
          <NavigationButton to={paths.archive} label={localize.archive} key={paths.archive} {...props} />,
        ];

      case UserType.SuperAuditor:
        return [
          <NavigationButton to={paths.admin.overview} label={localize.starsOverview} key={paths.admin.overview} {...props} />,
          <NavigationButton
            to={paths.admin.users.auditors}
            label={localize.userManagement}
            key={paths.admin.users.auditors}
            {...props}
          />,
          <NavigationButton to={paths.archive} label={localize.archive} key={paths.archive} {...props} />,
        ];
      default:
        return [];
    }
  };

  return (
    <div className="dd-layout">
      <Router history={history}>
        <div className="dd-sideContent">
          <img src="/assets/images/logo.svg" alt="CSE STAR Logo" className="dd-logo" onClick={() => history.push('/')} />
          <div>
            {loginContext.login ? (
              <Switch>
                <Route
                  path={paths.home}
                  render={(props) => (
                    <React.Fragment>
                      {loginContext.login ? getNavigationButtons(props, loginContext.login.userType) : null}
                    </React.Fragment>
                  )}
                />
              </Switch>
            ) : null}
          </div>
          <div className="dd-fixed">
            <div className="dd-download">
              <a href="https://download.cse-star.eu/CSE STAR Desktop Setup.exe">Desktop App herunterladen</a>
            </div>
            <div className="dd-version">Version 1.6.14</div>
          </div>
        </div>
        <div className="dd-mainContent">
          <div className="dd-topBar">
            <div className="dd-enviroment-notice">{isStage ? 'STAGE - Test Umgebung' : ''}</div>
            <UserMenu />
          </div>
          <div className="dd-content">
            {navigator.onLine || (!navigator.onLine && loginContext.login && loginContext.login.userType === 2) ? (
              <Switch>
                {components}
                <Redirect
                  exact
                  from={paths.home}
                  push
                  to={(() => {
                    if (!loginContext.login) {
                      return paths.signin.root;
                    }

                    if (loginContext.login && !navigator.onLine && loginContext.login.userType === 2) {
                      return paths.auditor.root;
                    }

                    if (loginContext.login) {
                      switch (loginContext.login.userType) {
                        case 0:
                          return paths.admin.overview;
                        case 1:
                          return paths.company.root;
                        case 2:
                          return paths.auditor.root;
                        case 3:
                          return paths.admin.users.auditors;
                      }
                    }

                    return paths.home;
                  })()}
                />
                {loginContext.login ? (
                  <Redirect
                    exact
                    from={paths.signin.root}
                    to={(() => {
                      if (loginContext.login && !navigator.onLine && loginContext.login.userType === 2) {
                        return paths.auditor.overview;
                      }

                      if (loginContext.login) {
                        switch (loginContext.login.userType) {
                          case 0:
                            return paths.admin.overview;
                          case 1:
                            return paths.company.root;
                          case 2:
                            return paths.auditor.root;
                          case 3:
                            return paths.admin.users.auditors;
                        }
                      }

                      return paths.home;
                    })()}
                  ></Redirect>
                ) : null}
                <Route component={PageNotFound}></Route>
              </Switch>
            ) : (
              <Offline></Offline>
            )}
          </div>
        </div>
      </Router>
    </div>
  );
};
